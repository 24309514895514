import axios, { AxiosError } from 'axios';

export class BaseHttpService {
  private http;
  private baseUrl = '';

  constructor(private requireAuth: boolean = true) {
    this.baseUrl = import.meta.env.APP_NODE_ENV === 'development' ? '/api' : import.meta.env.APP_API_URL;

    const NetworkConfig = {
      baseURL: this.baseUrl,
      timeout: 30000,
      headers: {
        Accept: 'application/json',
        from: 'node app',
      },
    };

    this.http = axios.create(NetworkConfig);
    this.http.interceptors.request.use(
      config2 => {
        let params: any = {}
        if (config2.method === 'post' && config2.data) {
          params = config2.data
        } else if (config2.method === 'get' && config2.params) {
          params = config2.params
        }
        params.ismobile = 1;
        return config2;
      },
      err => {
        return Promise.reject(err);
      }
    );
    this.http.interceptors.response.use(
      response => {
        const data = response.data
        if (data.code == 100000) {
          localStorage.removeItem('user')
          window.location.href = '/login'
        } else if (data.code == 0) {
          if (data.data && typeof data.data == 'object') {
            return data.data;
          } else {
            return data.code;
          }
        } else {
          return Promise.reject(data.code);
        }
      },
      async (error: AxiosError) => {
        console.debug(
          'response err: ',
          error.request?.responseURL,
          ' => ',
          error
        );
        // const app = useAppStore()
        if (error.response) {
          return Promise.reject(error.response.data);
        } else {
          if (error.message) {
            return Promise.reject(error.message);
          }
        }
        return Promise.reject(error);
      }
    );
  }

  protected buildAuthHeaders() {

    return null;
  }

  protected buildLanguageHeaders() {
    const languageid = "en";
    if (languageid) {
      return { 'language': languageid };
    } else {
      return { 'language': 'en' };
    }
  }

  /**
   * @param url
   * @param params
   * @param headers
   */
  protected get<T>(url: string, params?: any, headers?: any) {
    headers = this.processHeaders(headers, true);

    return this.http.get<any, T>(url, { 
      params,
      headers 
    });
  }

  /**
   * Gets response from the api
   * @param url
   * @param params
   * @param headers
   * @returns Observable<T>
   */
  protected getAs<T>(url: string, params?: any, headers?: any) {
    headers = this.processHeaders(headers, true);

    return this.http.get<any, T>(url, {
      params,
      headers
    });
  }

  protected post<T>(
    url: string,
    data: any,
    headers?: any,
    jsonContentType: boolean = true
  ) {
    headers = this.processHeaders(headers, jsonContentType);

    return this.http.post<any, T>(url, data, { headers });
  }

  protected put<T>(url: string, data: any, headers?: any) {
    headers = this.processHeaders(headers, true);

    return this.http.put<any, T>(url, data, { headers });
  }

  protected delete<T>(url: string, headers?: any) {
    headers = this.processHeaders(headers, true);

    return this.http.delete<any, T>(url, { headers });
  }

  private processHeaders(headers: any, jsonContentType: boolean): any {
    headers = headers || {};
    return Object.assign(
      {},
      ...[
        this.buildAuthHeaders(),
        this.buildLanguageHeaders(),
        jsonContentType
          ? {
            'Content-Type': 'application/json',
          }
          : {
            'Content-Type': 'multipart/form-data',
          },
        {
          Accept: 'application/json',
          from: 'node app',
        },
        headers,
      ]
    );
  }
}

export const baseHttpService = new BaseHttpService();
