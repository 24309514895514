import { ref } from 'vue'
import { defineStore } from 'pinia'
import i18n from '@/i18n'

import { useAppStore } from '@/stores/app'
import { userService } from '@/core/services/user.service'
import router from '@/router'

export const useUserStore = defineStore('user', () => {
  let userid = ref<string>('')
  let username = ref<string>('')
  let email = ref<string>('')
  let addtime = ref<string>('')
  let headpic = ref<string>('')
  let lasttime = ref<string>('')
  let logintime = ref<string>('')
  let managerlist = ref<any>([])
  let passwordisreset = ref<number>(0)
  let issuperpower = ref<number>(0)

  let app = useAppStore()
  // i18n
  const $t: any = i18n.global.t

  const login = async (params: object) => {
    try {
      const userInfo: any = await userService.login(params)
      console.log(userInfo);
      if (userInfo) {
        _fetchUserInfo(userInfo)
        return 1
      }
      return -1
    } catch (errorCode) {
      console.error('Error fetching login info:', errorCode);
      return errorCode
    }
  }

  const modifyPassword = async (params: object = {}) => {
    try {
      const userInfo: any = await userService.modifyPassword(params)
      return userInfo
    } catch (errorCode) {
      console.error('Error fetching modifyPassword:', errorCode);
      return errorCode
    }
  }

  const resetPassword = async (params: object = {}) => {
    try {
      const userInfo: any = await userService.resetPassword(params)
      return userInfo
    } catch (errorCode) {
      console.error('Error fetching resetPassword:', errorCode);
      return errorCode
    }
  }

  const sendResetPasswordEmail = async (params: object = {}) => {
    try {
      const userInfo: any = await userService.sendResetPasswordEmail(params)
      return userInfo
    } catch (errorCode) {
      console.error('Error fetching sendResetPasswordEmail:', errorCode);
      return errorCode
    }
  }

  // clients
  const getClients = async (params: object = {}) => {
    try {
      const clients: any = await userService.getClients(params)
      return clients
    } catch (errorCode) {
      console.error('Error fetching getClients:', errorCode);
      return errorCode
    }
  }

  const getClientInfo = async (params: object = {}) => {
    try {
      const clientInfo: any = await userService.getClientInfo(params)
      return clientInfo
    } catch (errorCode) {
      console.error('Error fetching getClientInfo:', errorCode);
      return errorCode
    }
  }

  const modifyClient = async (params: object = {}) => {
    try {
      const client: any = await userService.modifyClient(params)
      return client
    } catch (errorCode) {
      console.error('Error fetching modifyClient:', errorCode);
      return errorCode
    }
  }

  const getRegions = async (params: object = {}) => {
    try {
      const regions: any = await userService.getRegions(params)
      return regions
    } catch (errorCode) {
      console.error('Error fetching getRegions:', errorCode);
      return errorCode
    }
  }

  // RFQ
  const getInquiryOrderList = async (params: object = {}) => {
    try {
      const inquiryOrderList: any = await userService.getInquiryOrderList(params)
      return inquiryOrderList
    } catch (errorCode) {
      console.error('Error fetching getInquiryOrderList:', errorCode);
      return errorCode
    }
  }

  const getInquiryOrderDetail = async (params: object = {}) => {
    try {
      const inquiryOrderDetail: any = await userService.getInquiryOrderDetail(params)
      return inquiryOrderDetail
    } catch (errorCode) {
      console.error('Error fetching getInquiryOrderDetail:', errorCode);
      return errorCode
    }
  }

  const takeInquiryOrder = async (params: object = {}) => {
    try {
      const inquiryOrder: any = await userService.takeInquiryOrder(params)
      return inquiryOrder
    } catch (errorCode) {
      console.error('Error fetching takeInquiryOrder:', errorCode);
      return errorCode
    }
  }

  const closeInquiryOrder = async (params: object = {}) => {
    try {
      const inquiryOrder: any = await userService.closeInquiryOrder(params)
      return inquiryOrder
    } catch (errorCode) {
      console.error('Error fetching closeInquiryOrder:', errorCode);
      return errorCode
    }
  }

  const modifyInquiryOrderNotePrice = async (params: object = {}) => {
    try {
      const inquiryOrder: any = await userService.modifyInquiryOrderNotePrice(params)
      return inquiryOrder
    } catch (errorCode) {
      console.error('Error fetching modifyInquiryOrderNotePrice:', errorCode);
      return errorCode
    }
  }

  const submitInquiryOrderQuoteUnable = async (params: object = {}) => {
    try {
      const inquiryOrder: any = await userService.submitInquiryOrderQuoteUnable(params)
      return inquiryOrder
    } catch (errorCode) {
      console.error('Error fetching submitInquiryOrderQuoteUnable:', errorCode);
      return errorCode
    }
  }

  const submitInquiryOrderQuoteAble = async (params: object = {}) => {
    try {
      const inquiryOrder: any = await userService.submitInquiryOrderQuoteAble(params)
      return inquiryOrder
    } catch (errorCode) {
      console.error('Error fetching submitInquiryOrderQuoteAble:', errorCode);
      return errorCode
    }
  }

  // Blotter
  const getTradeOrderList = async (params: object = {}) => {
    try {
      const tradeOrderList: any = await userService.getTradeOrderList(params)
      return tradeOrderList
    } catch (errorCode) {
      console.error('Error fetching getTradeOrderList:', errorCode);
      return errorCode
    }
  }

  const getTradeOrderDetail = async (params: object = {}) => {
    try {
      const tradeOrderDetail: any = await userService.getTradeOrderDetail(params)
      return tradeOrderDetail
    } catch (errorCode) {
      console.error('Error fetching getTradeOrderDetail:', errorCode);
      return errorCode
    }
  }

  const takeTradeOrder = async (params: object = {}) => {
    try {
      const tradeOrder: any = await userService.takeTradeOrder(params)
      return tradeOrder
    } catch (errorCode) {
      console.error('Error fetching takeTradeOrder:', errorCode);
      return errorCode
    }
  }

  const closeTradeOrder = async (params: object = {}) => {
    try {
      const tradeOrder: any = await userService.closeTradeOrder(params)
      return tradeOrder
    } catch (errorCode) {
      console.error('Error fetching closeTradeOrder:', errorCode);
      return errorCode
    }
  }

  const submitTradeOrder = async (params: object = {}) => {
    try {
      const tradeOrder: any = await userService.submitTradeOrder(params)
      return tradeOrder
    } catch (errorCode) {
      console.error('Error fetching submitTradeOrder:', errorCode);
      return errorCode
    }
  }

  const dealTradeOrder = async (params: object = {}) => {
    try {
      const tradeOrder: any = await userService.dealTradeOrder(params)
      return tradeOrder
    } catch (errorCode) {
      console.error('Error fetching dealTradeOrder:', errorCode);
      return errorCode
    }
  }

  const notDealTradeOrder = async (params: object = {}) => {
    try {
      const tradeOrder: any = await userService.notDealTradeOrder(params)
      return tradeOrder
    } catch (errorCode) {
      console.error('Error fetching notDealTradeOrder:', errorCode);
      return errorCode
    }
  }

  const modDealTradeOrder = async (params: object = {}) => {
    try {
      const tradeOrder: any = await userService.modDealTradeOrder(params)
      return tradeOrder
    } catch (errorCode) {
      console.error('Error fetching modDealTradeOrder:', errorCode);
      return errorCode
    }
  }

  const scheduleTradeOrder = async (params: object = {}) => {
    try {
      const tradeOrder: any = await userService.scheduleTradeOrder(params)
      return tradeOrder
    } catch (errorCode) {
      console.error('Error fetching scheduleTradeOrder:', errorCode);
      return errorCode
    }
  }

  const getLifeCycleList = async (params: object = {}) => {
    try {
      const lifeCycleList: any = await userService.getLifeCycleList(params)
      return lifeCycleList
    } catch (errorCode) {
      console.error('Error fetching getLifeCycleList:', errorCode);
      return errorCode
    }
  }

  // LCM
  const getSimulateTradeOrderList = async (params: object = {}) => {
    try {
      const simulateTradeOrderList: any = await userService.getSimulateTradeOrderList(params)
      return simulateTradeOrderList
    } catch (errorCode) {
      console.error('Error fetching getSimulateTradeOrderList:', errorCode);
      return errorCode
    }
  }

  const getSimulateTradeOrderDetail = async (params: object = {}) => {
    try {
      const simulateTradeOrderDetail: any = await userService.getSimulateTradeOrderDetail(params)
      return simulateTradeOrderDetail
    } catch (errorCode) {
      console.error('Error fetching getSimulateTradeOrderDetail:', errorCode);
      return errorCode
    }
  }

  // news
  const getNewsList = async (params: object = {}) => {
    try {
      const newsList: any = await userService.getNewsList(params)
      return newsList
    } catch (errorCode) {
      console.error('Error fetching getNewsList:', errorCode);
      return errorCode
    }
  }

  const getNewsInfo = async (params: object = {}) => {
    try {
      const newsInfo: any = await userService.getNewsInfo(params)
      return newsInfo
    } catch (errorCode) {
      console.error('Error fetching getNewsInfo:', errorCode);
      return errorCode
    }
  }

  const addNews = async (params: object = {}) => {
    try {
      const news: any = await userService.addNews(params)
      return news
    } catch (errorCode) {
      console.error('Error fetching addNews:', errorCode);
      return errorCode
    }
  }

  const modifyNews = async (params: object = {}) => {
    try {
      const news: any = await userService.modifyNews(params)
      return news
    } catch (errorCode) {
      console.error('Error fetching modifyNews:', errorCode);
      return errorCode
    }
  }

  const deleteNews = async (params: object = {}) => {
    try {
      const news: any = await userService.deleteNews(params)
      return news
    } catch (errorCode) {
      console.error('Error fetching deleteNews:', errorCode);
      return errorCode
    }
  }

  const getTagsList = async (params: object = {}) => {
    try {
      const tagsList: any = await userService.getTagsList(params)
      return tagsList
    } catch (errorCode) {
      console.error('Error fetching getTagsList:', errorCode);
      return errorCode
    }
  }

  const upNews = async (params: object = {}) => {
    try {
      const news: any = await userService.upNews(params)
      return news
    } catch (errorCode) {
      console.error('Error fetching upNews:', errorCode);
      return errorCode
    }
  }

  const downNews = async (params: object = {}) => {
    try {
      const news: any = await userService.downNews(params)
      return news
    } catch (errorCode) {
      console.error('Error fetching downNews:', errorCode);
      return errorCode
    }
  }

  // tags
  const addTag = async (params: object = {}) => {
    try {
      const tag: any = await userService.addTag(params)
      return tag
    } catch (errorCode) {
      console.error('Error fetching addTag:', errorCode);
      return errorCode
    }
  }

  const modifyTag = async (params: object = {}) => {
    try {
      const tag: any = await userService.modifyTag(params)
      return tag
    } catch (errorCode) {
      console.error('Error fetching modifyTag:', errorCode);
      return errorCode
    }
  }

  const upTag = async (params: object = {}) => {
    try {
      const tag: any = await userService.upTag(params)
      return tag
    } catch (errorCode) {
      console.error('Error fetching upTag:', errorCode);
      return errorCode
    }
  }

  const downTag = async (params: object = {}) => {
    try {
      const tag: any = await userService.downTag(params)
      return tag
    } catch (errorCode) {
      console.error('Error fetching downTag:', errorCode);
      return errorCode
    }
  }

  // boss
  const getBossUserList = async (params: object = {}) => {
    try {
      const bossUserList: any = await userService.getBossUserList(params)
      return bossUserList
    } catch (errorCode) {
      console.error('Error fetching getBossUserList:', errorCode);
      return errorCode
    }
  }

  const getBossUserInfo = async (params: object = {}) => {
    try {
      const bossUserInfo: any = await userService.getBossUserInfo(params)
      return bossUserInfo
    } catch (errorCode) {
      console.error('Error fetching getBossUserInfo:', errorCode);
      return errorCode
    }
  }

  const addBossUser = async (params: object = {}) => {
    try {
      const bossUser: any = await userService.addBossUser(params)
      return bossUser
    } catch (errorCode) {
      console.error('Error fetching addBossUser:', errorCode);
      return errorCode
    }
  }

  const modifyBossUser = async (params: object = {}) => {
    try {
      const bossUser: any = await userService.modifyBossUser(params)
      return bossUser
    } catch (errorCode) {
      console.error('Error fetching modifyBossUser:', errorCode);
      return errorCode
    }
  } 

  const deleteBossUser = async (params: object = {}) => {
    try {
      const bossUser: any = await userService.deleteBossUser(params)
      return bossUser
    } catch (errorCode) {
      console.error('Error fetching deleteBossUser:', errorCode);
      return errorCode
    }
  }

  const getBossRoles = async (params: object = {}) => {
    try {
      const bossRoles: any = await userService.getBossRoles(params)
      return bossRoles
    } catch (errorCode) {
      console.error('Error fetching getBossRoles:', errorCode);
      return errorCode
    }
  }

  // oss
  const ossUploadUrl = async (params: object = {}) => {
    try {
      const ossUploadUrl: any = await userService.ossUploadUrl(params)
      return ossUploadUrl
    } catch (errorCode) {
      console.error('Error fetching ossUploadUrl:', errorCode);
      return errorCode
    }
  }

  const getOssUploadUrl = async (params: object = {}) => {
    try {
      const ossUploadUrl: any = await userService.getOssUploadUrl(params)
      return ossUploadUrl
    } catch (errorCode) {
      console.error('Error fetching getOssUploadUrl:', errorCode);
      return errorCode
    }
  }

  const checkPermission = (loginuid: string) => {
    if (userid.value == loginuid) {
      return true
    }
    if (issuperpower.value == 200) {
      return true
    }
    return false
  }

  const logout = async () => {
    try {
      // if (usercheck.value) {
        await userService.logout()
      // }
      _clearUserInfo()
      _redirectToHome()
      return 1
    } catch (errorCode) {
      console.error('Error fetching logout:', errorCode);
      _clearUserInfo()
      _redirectToHome()
      return errorCode
    }
  }

  const _redirectToHome = () => {
    const currentRoute = router.currentRoute.value;
    if (currentRoute.meta?.auth !== false) {
      router.replace('/login')
    }
  }

  const _clearUserInfo = () => {
    userid.value = ''
    username.value = ''
    email.value = ''
    addtime.value = ''
    headpic.value = ''
    lasttime.value = ''
    logintime.value = ''
    managerlist.value = []
    issuperpower.value = 0
    passwordisreset.value = 0
  }

  const _fetchUserInfo = (userInfo: any) => {
    userid.value = userInfo.id
    username.value = userInfo.username
    email.value = userInfo.email
    addtime.value = userInfo.addtime
    headpic.value = userInfo.headpic
    lasttime.value = userInfo.lasttime
    logintime.value = userInfo.logintime
    managerlist.value = userInfo.managerlist
    passwordisreset.value = userInfo.passwordisreset
    issuperpower.value = userInfo.issuperpower
  }

  return { userid, username, email, addtime, headpic, lasttime, logintime, managerlist, issuperpower, passwordisreset, login, logout, getClients, getClientInfo, modifyClient, checkPermission, getRegions, getInquiryOrderList, getInquiryOrderDetail, takeInquiryOrder, closeInquiryOrder, submitInquiryOrderQuoteUnable, submitInquiryOrderQuoteAble, getTradeOrderList, getTradeOrderDetail, takeTradeOrder, closeTradeOrder, submitTradeOrder, dealTradeOrder, notDealTradeOrder, modDealTradeOrder, scheduleTradeOrder, getLifeCycleList, getSimulateTradeOrderList, getSimulateTradeOrderDetail, getBossUserList, getBossUserInfo, addBossUser, modifyBossUser, deleteBossUser, getBossRoles, getNewsList, getNewsInfo, addNews, modifyNews, deleteNews, getTagsList, upNews, downNews, upTag, downTag, addTag, modifyTag, ossUploadUrl, getOssUploadUrl, modifyInquiryOrderNotePrice, modifyPassword, resetPassword, sendResetPasswordEmail }
}, {
  persist: [
    {
      debug: import.meta.env.APP_NODE_ENV === 'development',
      pick: ['userid', 'username', 'email', 'addtime', 'headpic', 'lasttime', 'logintime', 'managerlist', 'passwordisreset', 'issuperpower']
    }
  ]
})