import IntlMessageFormat from 'intl-messageformat'
import type { MessageCompiler, CompileError, MessageContext } from 'vue-i18n'

export const messageCompiler: MessageCompiler = (
  message,
  { locale, key, onError }
) => {
  if (typeof message === 'string') {
    /**
     * You can tune your message compiler performance more with your cache strategy or also memoization at here
     */
    let index = 0;
    const tags = extractHtmlTags(message);
    const formattedMessage = message.replace(/%s/g, () => `{${index++}}`).replace(/<[^>]+>/g, '%s');
    const formatter = new IntlMessageFormat(formattedMessage, locale);
    return (ctx: MessageContext) => {
      if (!isEmpty(ctx.values)) {
        let retmsg: string = formatter.format(ctx.values) as string;
        if (tags.length > 0) {
          tags.forEach(tag => {
            retmsg = retmsg.replace('%s', tag);
          });
        }
        return retmsg;
      } else {
        return message;
      }
    }
  } else {
    /**
     * for AST.
     * If you would like to support it,
     * You need to transform locale messages such as `json`, `yaml`, etc. with the bundle plugin.
     */
    onError && onError(new Error('not support for AST') as CompileError)
    return () => key
  }
}

const isEmpty = (obj: Record<string, any>): boolean => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

const extractHtmlTags = (str: string) => {
  const regex = /(<[^>]+>)/g;
  return str.match(regex) || [];
};