import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { useAppStore } from '@/stores/app'
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('../views/AboutView.vue'),
    },
    {
      path: '/login',
      name: 'login',
      meta: {
        auth: false,
        noMenu: true,
      },
      component: () => import('../views/LoginView.vue'),
    },
    {
      path: '/clients',
      name: 'clients',
      meta: {
        menu: 'clients',
      },
      component: () => import('../views/clients/ClientsView.vue'),
    },
    {
      path: '/clients/:uid',
      name: 'clientDetail',
      meta: {
        menu: 'clients',
      },
      component: () => import('../views/clients/ClientDetailView.vue'),
    },
    {
      path: '/rfq',
      name: 'rfq',
      meta: {
        menu: 'rfq',
      },
      component: () => import('../views/rfq/RFQView.vue'),
    },
    {
      path: '/rfq/:orderno',
      name: 'rfqDetail',
      meta: {
        menu: 'rfq',
      },
      component: () => import('../views/rfq/RFQDetailView.vue'),
    },
    {
      path: '/blotter',
      name: 'blotter',
      meta: {
        menu: 'blotter',
      },
      component: () => import('../views/blotter/BlotterView.vue'),
    },
    {
      path: '/blotter/:orderno',
      name: 'blotterDetail',
      meta: {
        menu: 'blotter',
      },
      component: () => import('../views/blotter/BlotterDetailView.vue'),
    },
    {
      path: '/lcm',
      name: 'lcm',
      meta: {
        menu: 'lcm',
      },
      component: () => import('../views/lcm/LCMView.vue'),
    },
    {
      path: '/lcm/:orderno',
      name: 'lcmDetail',
      meta: {
        menu: 'lcm',
      },
      component: () => import('../views/lcm/LCMDetailView.vue'),
    },
    {
      path: '/market',
      name: 'market',
      meta: {
        menu: 'market',
      },
      component: () => import('../views/market/MarketView.vue'),
    },
    {
      path: '/market/add',
      name: 'marketAdd',
      meta: {
        menu: 'market',
      },
      component: () => import('../views/market/MarketAddView.vue'),
    },
    {
      path: '/market/detail/:id',
      name: 'marketDetail',
      meta: {
        menu: 'market',
      },
      component: () => import('../views/market/MarketDetailView.vue'),
    },
    {
      path: '/market/edit/:id',
      name: 'marketEdit',
      meta: {
        menu: 'market',
      },
      component: () => import('../views/market/MarketEditView.vue'),
    },
    {
      path: '/users',
      name: 'users',
      meta: {
        menu: 'users',
      },
      component: () => import('../views/users/UsersView.vue'),
    }
  ],
})

router.beforeEach((to, from, next) => {
  const auth = to.meta.auth;
  if (auth === false) {
    const app = useAppStore()
    if (app.isLoggedIn) {
      next({ name: 'home' })
    }
  } else {
    const app = useAppStore()
    if (!app.isLoggedIn) {
      next({ name: 'login' })
    }
  } 
  next();
})

export default router
