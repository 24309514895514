import i18n from '@/i18n';

let $t: any = i18n.global.t

export const imgUrl = (img: string) => {
  return new URL("/assets/img/" + img + "?v=" + import.meta.env.APP_ASSETS_VERSION, import.meta.url).href
}

export const imageUrl = (img: string) => {
  return new URL("/assets/images/" + img + "?v=" + import.meta.env.APP_ASSETS_VERSION, import.meta.url).href;
}

export const imageFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = (reader.result as string).split(',')[1];
      resolve(base64String);
    }
    reader.onerror = (error) => reject(error);
  });
};

export const base64ImageFromUrl = async (url: string): Promise<string | null> => {
  try {
    // Fetch the image as a blob
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const blob = await response.blob();
    // Convert the blob to a Base64-encoded string
    let base64ImageBlob = await new Promise<string | null>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
      reader.readAsBinaryString(blob);
    });
    base64ImageBlob = 'data:image/png;base64,' + base64ImageBlob
    return base64ImageBlob;
  } catch (error) {
    console.error('Error downloading or converting image:', error);
    return null;
  }
};

export const toHttpUrl = (url: string, redirect: boolean = false) => {
  const usercheck = localStorage.getItem('user/usercheck')
  const access_token = localStorage.getItem('user/access_token')
  const search = window.location.search
  url = import.meta.env.APP_PLAY_URL + url
  if (usercheck || access_token) {
    if (search) {
      url += search + '&usercheck=' + usercheck + '&access_token=' + access_token
    } else {
      url += '?usercheck=' + usercheck + '&access_token=' + access_token
    }
  }
  if (redirect && url) {
    window.location.href = url;
  }
  return url;
}

export const nf = (number: any, decimals = 2, spacing = ",") => {
  if (number === null || number === undefined) {
    return '-';
  }

  number = parseFloat(number);

  if (!isNaN(number)) {
    number = number.toLocaleString('en-US', { minimumFractionDigits: decimals, maximumFractionDigits: decimals }).replace(/,/g, spacing);
  }
  let str_number = number.toString();
  if (str_number.slice(-2) === '.0') {
    number = str_number.slice(0, -2);
  }
  return number;
}

export const pf = (number: any, decimals = 2, spacing = " ") => {
  if (number === null || number === undefined || number === '') {
    return '';
  }
  
  number = parseFloat(number);

  if (!isNaN(number)) {
    number = number.toLocaleString('en-US', { minimumFractionDigits: decimals, maximumFractionDigits: decimals }).replace(/,/g, spacing);
  }
  let str_number = number.toString();
  if (str_number.slice(-2) === '.0') {
    number = str_number.slice(0, -2);
  }
  return number + '%';
}


// date from timestamp
export const df = (datetime: string, format: string = 'yyyy/MM/dd HH:mm') => {
  if (datetime === null || datetime === undefined || datetime === '') {
    return '';
  }
  const dfDate = new Date(datetime);
  const options: Intl.DateTimeFormatOptions = {};
  if (format.includes('MM')) options.month = '2-digit';
  if (format.includes('dd')) options.day = '2-digit';
  if (format.includes('yyyy')) options.year = 'numeric';
  if (format.includes('HH')) options.hour = '2-digit';
  if (format.includes('mm')) options.minute = '2-digit';
  if (format.includes('ss')) options.second = '2-digit';
  options.hour12 = false;
  return dfDate.toLocaleString(undefined, options).replace(',', '');
}

export const dfLocal = (datetime: string, format: string = 'MM/dd/yyyy HH:mm:ss') => {
  // datetime is in UTC: 2024-12-14 10:00:00
  const utcDate = new Date(datetime + ' UTC');
  const options: Intl.DateTimeFormatOptions = {};
  if (format.includes('MM')) options.month = '2-digit';
  if (format.includes('dd')) options.day = '2-digit';
  if (format.includes('yyyy')) options.year = 'numeric';
  if (format.includes('HH')) options.hour = '2-digit';
  if (format.includes('mm')) options.minute = '2-digit';
  if (format.includes('ss')) options.second = '2-digit';
  options.hour12 = false;
  return utcDate.toLocaleString(undefined, options).replace(',', '');
}

export const ftime = (format: string, timestamp: number | null = null) => {
  const isWindows = navigator.userAgent.includes('Windows');

  if (format.includes('%e') && isWindows) {
    format = format.replace(/%e/g, '%#d');
  }

  if (format.includes('%G') && isWindows) {
    format = format.replace(/%G/g, '%Y');
  }

  if (timestamp === null) {
    timestamp = Date.now() / 1000; // Current time in seconds
  }

  if (isNaN(timestamp)) {
    timestamp = new Date(timestamp).getTime() / 1000; // Convert to seconds
  }

  return new Date(timestamp * 1000).toLocaleString('en-US', { timeZone: 'UTC', formatMatcher: 'basic' });
}

// time left from timestamp
export const tl = (datetime: string, defaultText = '00:00') => {
  let out = defaultText;
  if (!datetime) {
    return out;
  }
  const targetDate = new Date(datetime);
  const currentDate = new Date();
  let diff = targetDate.getTime() - currentDate.getTime();
  if (diff <= 0) {
    return out;
  }
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  diff -= days * (1000 * 60 * 60 * 24);

  const hours = Math.floor(diff / (1000 * 60 * 60));
  diff -= hours * (1000 * 60 * 60);

  const minutes = Math.floor(diff / (1000 * 60));
  diff -= minutes * (1000 * 60);

  const seconds = Math.floor(diff / 1000);

  out = '';
  if (days > 0) {
    out += `${days}D `;
  }
  if (hours > 0 || days > 0) {
    out += `${String(hours).padStart(2, '0')}:`;
  }
  out += `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

  return out;
}

export const randomNumber = (min: number, max: number, len: number, exclude: any = []) => {
  const uniqueNumbers = new Set();
  while (uniqueNumbers.size < len) {
    const num = Math.floor(Math.random() * (max - min + 1)) + min;
    if (!exclude.includes(num)) {
      uniqueNumbers.add(num);
    }
  }
  return Array.from(uniqueNumbers).sort((a: any, b: any) => a - b);
}

/**
 * Calculate number of combinations needed to cover all selected numbers
 *
 * @returns {number} newCombs - The number of combinations needed to cover all numbers
 */
export const combine = (numbers: any, n: any) => {
  let combines: any[] = [], last, found, tmp;
  if (numbers.length === 0) {
    return [];
  }
  // Magic
  if (n === 1) {
    for (let i = 0; i < numbers.length; i++) {
      combines.push([numbers[i]]);
    }
    return combines;
  } else {
    const oneLevelLower = combine(numbers, n - 1);
    oneLevelLower.forEach((row: any, idx: any) => {
      last = row[n - 2];
      found = false;
      for (let i = 0; i < numbers.length; i++) {
        if (numbers[i] == last) {
          found = true;
          continue;
        }
        if (found === true) {
          tmp = row.slice(0);
          tmp.push(numbers[i]);
          combines.push(tmp.slice(0));
        }
      }
    });
    return combines;
  }
}

export const scrollToTop = () => {
  let top: number = document.documentElement.scrollTop || document.body.scrollTop;
  // ccroll to top
  const timeTop = setInterval(() => {
    document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
    if (top <= 0) {
      clearInterval(timeTop);
    }
  }, 10);
}
