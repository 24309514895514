<template>
  <el-aside class="left-aside">
    <div class="left-menu">
      <el-image class="logo" :src="imageUrl('logo_hor.svg')" fit="contain" />
      <el-menu
        active-text-color="#E5AB4D"
        background-color="#191C29"
        class="el-menu-vertical"
        :default-active="menuActive"
        text-color="#fff"
        @select="handleSelect"
        router>
        <el-menu-item index="/clients">
          <el-icon>
            <SvgIcon icon="clients" :color="menuActive === '/clients' ? '#E5AB4D' : '#ADB1B6'" />
          </el-icon>
          <span>Clients</span>
        </el-menu-item>
        <el-menu-item index="/rfq">
          <el-icon>
            <SvgIcon icon="rfq" :color="menuActive === '/rfq' ? '#E5AB4D' : '#ADB1B6'" />
          </el-icon>
          <span>RFQ</span>
        </el-menu-item>
        <el-menu-item index="/blotter">
          <el-icon>
            <SvgIcon icon="blotter" :color="menuActive === '/blotter' ? '#E5AB4D' : '#ADB1B6'" />
          </el-icon>
          <span>Blotter</span>
        </el-menu-item>
        <el-menu-item index="/lcm">
          <el-icon>
            <SvgIcon icon="lcm" :color="menuActive === '/lcm' ? '#E5AB4D' : '#ADB1B6'" />
          </el-icon>
          <span>Simulated Trading</span>
        </el-menu-item>
        <el-menu-item index="/market">
          <el-icon>
            <SvgIcon icon="market" :color="menuActive === '/market' ? '#E5AB4D' : '#ADB1B6'" />
          </el-icon>
          <span>Research & Market</span>
        </el-menu-item>
        <el-menu-item index="/users" v-if="user.issuperpower == 200">
          <el-icon>
            <SvgIcon icon="users" :color="menuActive === '/users' ? '#E5AB4D' : '#ADB1B6'" />
          </el-icon>
          <span>Users</span>
        </el-menu-item>
      </el-menu>
      <div class="bottom-menu">
        <div class="user-info">
          <el-avatar size="small" src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" />
          <span class="user-name">{{ username }}</span>
        </div>
        <div class="btn-group">
          <!-- <el-dropdown trigger="click" popper-class="password-dropdown">
            <span class="el-dropdown-link">
              <el-image class="btn-setting" :src="imageUrl('auth/setting.svg')" fit="contain" />
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="showChangePassword = true">Change Password</el-dropdown-item>
                <el-dropdown-item @click="showResetPassword = true">Reset Password</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown> -->
          <el-image class="btn-setting" :src="imageUrl('auth/setting.svg')" fit="contain" @click="showChangePassword = true" />
          <el-image class="btn-logout" :src="imageUrl('auth/logout.svg')" fit="contain" @click="logout" />
        </div>
      </div>
      <el-dialog v-model="showChangePassword" title="Change Password" width="500">
        <el-form :model="changePasswordForm" :rules="inputRules" label-position="top" ref="changePasswordFormRef">
          <el-form-item label="Password" prop="password" label-width="140px" :error="passwordFormError.password">
            <el-input v-model="changePasswordForm.password" autocomplete="off" show-password placeholder="Current Password" />
          </el-form-item>
          <el-form-item label="New Password" prop="newPassword" label-width="140px" :error="passwordFormError.newPassword">
            <el-input v-model="changePasswordForm.newPassword" autocomplete="off" show-password placeholder="Number, letter and symbol, 8~15 characters long" />
          </el-form-item>
          <el-form-item label="Confirm Password" prop="confirmPassword" label-width="140px" :error="passwordFormError.confirmPassword">
            <el-input v-model="changePasswordForm.confirmPassword" autocomplete="off" show-password placeholder="Retype Password" />
          </el-form-item>
        </el-form>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="resetForm(changePasswordFormRef)">Cancel</el-button>
            <el-button type="primary" :loading="loading" @click="changePassword(changePasswordFormRef)" :disabled="!changePasswordForm.password || !changePasswordForm.newPassword || !changePasswordForm.confirmPassword">OK</el-button>
          </div>
        </template>
      </el-dialog>
    </div>
  </el-aside>
</template>

<script setup lang="ts">
  import { reactive, ref, watch } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useUserStore } from '@/stores/user'
  import { useRoute, useRouter } from 'vue-router';
  import { imageUrl } from '@/utils';
  import { ElMessage, ElMessageBox } from 'element-plus'
  import type { FormRules, FormInstance } from 'element-plus'

  const user = useUserStore()
  const { username, userid } = storeToRefs(user)

  interface RuleForm {
    password: string
    newPassword: string
    confirmPassword: string
  }

  
  const route = useRoute();
  const router = useRouter();
  let loading = ref(false)
  let menuActive = ref('clients');
  let showChangePassword = ref(false);
  const changePasswordFormRef = ref<any>(null)
  const changePasswordForm = reactive<RuleForm>({
    password: '',
    newPassword: '',
    confirmPassword: '',
  })

  const validateConfirmPassword = (rule: any, value: any, callback: any) => {
    if (value !== changePasswordForm.newPassword) {
      callback(new Error('Passwords are not the same'));
    } else {
      callback();
    }
  }

  const inputRules = reactive<FormRules<RuleForm>>({
    password: [
      { required: true, message: 'Please input password', trigger: 'blur' },
      { min: 8, max: 15, message: 'Number, letter and symbol, 8~15 characters long', trigger: 'blur' },
    ],
    newPassword: [
      { required: true, message: 'Please input new password', trigger: 'blur' },
      { min: 8, max: 15, message: 'Number, letter and symbol, 8~15 characters long', trigger: 'blur' },
    ],
    confirmPassword: [
      { required: true, message: 'Please input confirm password', trigger: 'blur' },
      { min: 8, max: 15, message: 'Number, letter and symbol, 8~15 characters long', trigger: 'blur' },
      { validator: validateConfirmPassword, trigger: 'blur' }
    ]
  })
  let passwordFormError = ref({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });

  if (window.location.pathname) {
    const firstPath = '/' + window.location.pathname.split('/')[1];
    menuActive.value = firstPath;
  }

  const changePassword = async (formEl: FormInstance | undefined) => {
    if (!formEl) return
    await formEl.validate((valid, fields) => {
      if (valid) {
        if (changePasswordForm.password === changePasswordForm.newPassword) {
          passwordFormError.value.newPassword = 'The new password cannot be the same as the old password';
          return;
        }
        if (changePasswordForm.newPassword !== changePasswordForm.confirmPassword) {
          passwordFormError.value.confirmPassword = 'The new password and confirm password do not match';
          return;
        }
        loading.value = true
        user.modifyPassword({
          "oldpassword": changePasswordForm.password,
          "password": changePasswordForm.newPassword
        }).then((res) => {
          loading.value = false
          console.log(111, res)
          if (res == 0) {
            showChangePassword.value = false;
            ElMessageBox.alert(
              'Password changed successfully',
              'Warning',
              {
                confirmButtonText: 'Reload',
                type: 'success',
                center: true,
              }
            ).then(() => {
              user.logout()
            }).catch(() => {
              user.logout()
            })
          } else if (res == 50) {
            passwordFormError.value.password = 'Wrong Password'
            return
          } else {
            ElMessage({
              type: 'error',
              message: 'Failed to change password',
            })
          }
        }).catch((error) => {
          loading.value = false
          ElMessage({
            type: 'error',
            message: 'Failed to change password',
          })
        })
      } else {
        console.log('error submit!', fields)
      }
    })
  }

  const resetForm = (formEl: FormInstance | undefined) => {
    if (!formEl) return
    formEl.resetFields()
    showChangePassword.value = false;
  }

  const handleSelect = (index: string) => {
    menuActive.value = index;
  }

  const logout = () => {
    ElMessageBox.confirm(
      'Are you sure to logout?',
      'Warning',
      {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
        center: true,
      }
    ).then(() => {
      user.logout()
      // router.replace('/login');
      ElMessage({
        type: 'success',
        message: 'Logout completed',
      })
    }).catch(() => { })
  }
</script>

<style lang="scss" scoped>
.left-aside {
  width: 200px;
  height: 100vh;
  overflow-y: auto;
  background-color: #191C29;

  .logo {
    width: 110px;
    height: 34px;
    margin-top: 0px;
    margin-bottom: 20px;
    overflow: unset;
    left: -18px;
  }

  .el-menu-item {
    background: #202435;
    border-radius: 6px;
    margin-top: 4px;
    --el-menu-item-height: 38px !important;
    --el-menu-base-level-padding: 10px !important;
    --el-menu-level-padding: 10px !important;

    &.is-active {
      background: linear-gradient(90deg, #403224 0%, #191C29 100%);
    }
  }
}

.left-menu {
  width: 200px;
  height: 100%;
  padding-top: 24px;
  background-color: #191C29;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  :deep(.el-menu) {
    border-right: 0px;
  }
}

.bottom-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  background: #202435;
  width: 100%;
  height: 50px;
  line-height: 50px;
  border-radius: 4px;
  margin-top: 4px;
  padding: 0 10px;
  position: absolute;
  bottom: 0;

  .user-info {
    font-size: 12px;
    font-weight: 600;
    align-items: left;
    display: flex;
    align-items: center;

    span.user-name {
      margin-left: 4px;
    }
  }

  .btn-group {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .btn-setting,
  .btn-logout {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 8px;
  }

  .el-dropdown {
    --el-dropdown-menu-box-shadow: none;
    --el-dropdown-menuItem-hover-fill: none;
    --el-dropdown-menuItem-hover-color: none;
  }
}

:deep(.password-dropdown) {
  background: #202435 !important;
}

:deep(.el-popper) {
  .is-light {
    background: #202435 !important;
  }
}

:deep(.el-dialog) {
  padding: 50px 60px;
  border-radius: 12px;
  .el-dialog__title {
    font-size: 20px;
    font-weight: 800;
  }
  .el-form-item {
    margin-bottom: 16px;
    &.el-form-item--label-top .el-form-item__label {
      margin-bottom: 0px;
    }
  }
}
</style>
