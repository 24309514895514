import { createApp } from 'vue'

import App from './App.vue'
import i18n from './i18n';
import router from './router'
import pinia from '@/stores/index'
import SvgIcon from '@/components/icons/SvgIcon.vue';

import 'amfe-flexible'
import 'vant/lib/index.css'
import './assets/css/main.scss'

import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'

const app = createApp(App)
app.use(pinia)
app.use(router)
app.use(i18n)
app.use(ElementPlus)

// 注册 Element Plus 图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
// 注册 SvgIcon 为全局组件
app.component('SvgIcon', SvgIcon);

app.mount('#app')
