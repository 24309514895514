export default {
  "global_or": "OR",
  "global_auto": "Auto",
  "global_stop": "Stop",
  "global_more": "more",
  "global_about_us": "About Us",
  "global_language": "Language",
  "global_results": "Lotto Results",
  "global_real_money": "Real money",
  "global_bonus_money": "Bonus money",
  "global_account_balance": "Account Balance",
  "global_show": "show",
  "global_hide": "hide",
  "global_loaded": "Loaded",
  "global_price": "Price",
  "global_draw": "Draw",
  "global_drawings": "Drawings",
  "global_total": "Total",
  "global_bonus": "Bonus",
  "global_real": "Real",
  "global_home": "Home",
  "global_info": "Info",
  "global_contact": "Contact",
  "global_draw_date": "Draw date",
  "global_next_draw": "Waiting for next draw",
  "global_popular": "Popular",
  "global_jackpot": "Jackpot",
  "global_left": "left",
  "global_lines": "Line",
  "global_loading": "Loading",
  "global_login": "Log In",
  "global_signup": "Sign Up",
  "global_logout": "Log Out"
}
