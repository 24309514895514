// src/i18n/index.ts
import { watch } from 'vue';
import { createI18n, type I18n } from 'vue-i18n';
import en from './locales/en';
import { Locale } from 'vant';

import { messageCompiler } from './compilation'

const messages = {
  en: {
    ...en
  }
};

const i18n: I18n = createI18n({
  locale: 'en',
  legacy: false,
  allowComposition: true,
  globalInjection: true,
  messageCompiler,
  messages
});

// const setVantLanguage = (locale: any) => {
//   // console.log('setVantLanguage:', locale, typeof locale);
//   if (locale === 'en') {
//     Locale.use('en-US', enUS);
//   } else if (locale === 'pt') {
//     Locale.use('pt-BR', ptBR);
//   }
// };

const locale: any = i18n.global.locale;
// setVantLanguage(locale.value);

// watch(
//   () => locale.value,
//   (newLocale) => setVantLanguage(newLocale)
// );
export default i18n;
