import { ref, computed, watch } from 'vue'
import { defineStore } from 'pinia'
import { useUserStore } from '@/stores/user'

export const useAppStore = defineStore('app', () => {
  let countryid = ref<string>('')
  let platform = ref<number>(2000)
  // language
  let language = ref<string>('en')
  // currency
  let currency = ref<string>('HKD')
  let currencysymbol = ref<string>('$')
  let symbollocation = ref<string>('before')
  // popup
  let loading = ref<boolean>(false)

  // user store
  const user = useUserStore()
  const isLoggedIn = computed<boolean>(() => {
    return user.userid ? true : false
  })
  const userid = computed(() => {
    return user.userid || ''
  })
  const username = computed(() => {
    return user.username || ''
  })
  const useremail = computed(() => {
    return user.email || ''
  })

  const changeLanguage = (locale: string, name: string) => {
    language.value = locale
  }
  const changeCurrency = (currencyid: any) => {
    currency.value = currencyid
  }
  const onInit = () => {
    // initUserApp()
  }

  // init
  onInit()
  return { isLoggedIn, userid, username, useremail, countryid, platform, language, currency, currencysymbol, symbollocation, loading, changeLanguage, changeCurrency }
}, {
  persist: [
    {
      debug: import.meta.env.APP_NODE_ENV === 'development',
      pick: ['countryid', 'platform', 'language', 'languageid']
    },
    {
      key: 'app/currency',
      pick: ['currency'],
      serializer: {
        serialize: (state: any) => state.currency,
        deserialize: (value: any) => value
      },
      storage: localStorage // Set storage to use the main domain instead of subdomain
    },
    {
      key: 'app/countryid',
      pick: ['countryid'],
      serializer: {
        serialize: (state: any) => state.countryid,
        deserialize: (value: any) => value
      },
      storage: localStorage // Set storage to use the main domain instead of subdomain
    },
    {
      key: 'app/languageid',
      pick: ['languageId'],
      serializer: {
        serialize: (state: any) => state.languageId,
        deserialize: (value: any) => value
      },
      storage: localStorage // Set storage to use the main domain instead of subdomain
    }
  ]
})