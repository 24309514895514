<template>
  <el-container>
    <LeftMenu v-if="!$route.meta.noMenu" />
    <el-container>
      <router-view :key="$route.fullPath" v-slot="{ Component }">
        <transition name="fade" mode="out-in" appear>
          <keep-alive include="*">
            <component :is="Component" />
          </keep-alive>
        </transition>
      </router-view>
    </el-container>
  </el-container>
</template>

<style></style>
