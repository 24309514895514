import { BaseHttpService } from './basehttp.service';

export class UserService extends BaseHttpService {
  constructor() {
    super(false);
  }

  loginDebug(data: object): any {
    const url = '/admin/login/debug';
    return this.post(url, data);
  }

  login(data: object): any {
    const url = '/admin/login/password';
    return this.post(url, data);
  }

  logout(): any {
    const url = '/admin/login/logout';
    return this.post(url, {});
  }

  modifyPassword(data: object): any {
    const url = '/admin/boss/user/modpassword';
    return this.post(url, data);
  }

  resetPassword(data: object): any {
    const url = '/admin/boss/user/resetpassword';
    return this.post(url, data);
  }

  sendResetPasswordEmail(data: object): any {
    const url = '/admin/boss/user/sendmail';
    return this.post(url, data);
  }

  // clients
  getClients(data: object): any {
    const url = '/admin/user/account/list';
    return this.get(url, data);
  }
  getClientInfo(data: object): any {
    const url = '/admin/user/account/info';
    return this.get(url, data);
  }
  modifyClient(data: object): any {
    const url = '/admin/user/account/modify';
    return this.post(url, data);
  }
  getRegions(data: object): any {
    const url = '/admin/base/region/list';
    return this.get(url, data);
  }

  // news
  getNewsList(data: object): any {
    const url = '/admin/news/list';
    return this.get(url, data);
  }
  getNewsInfo(data: object): any {
    const url = '/admin/news/info';
    return this.get(url, data);
  }
  addNews(data: object): any {
    const url = '/admin/news/add';
    return this.post(url, data);
  }
  modifyNews(data: object): any {
    const url = '/admin/news/modify';
    return this.post(url, data);
  }
  deleteNews(data: object): any {
    const url = '/admin/news/delete';
    return this.post(url, data);
  }
  getTagsList(data: object): any {
    const url = 'admin/base/enum/list';
    return this.get(url, data);
  }
  upNews(data: object): any {
    const url = '/admin/news/up';
    return this.post(url, data);
  }
  downNews(data: object): any {
    const url = '/admin/news/down';
    return this.post(url, data);
  }
  addTag(data: object): any {
    const url = '/admin/base/enum/add';
    return this.post(url, data);
  }
  modifyTag(data: object): any {
    const url = '/admin/base/enum/modify';
    return this.post(url, data);
  }
  upTag(data: object): any {
    const url = '/admin/base/enum/up';
    return this.post(url, data);
  }
  downTag(data: object): any {
    const url = '/admin/base/enum/down';
    return this.post(url, data);
  }

  // orders - Trade Inquiry Order
  getInquiryOrderList(data: object): any {
    const url = '/admin/trade/inquiryorder/list';
    return this.get(url, data);
  }
  getInquiryOrderDetail(data: object): any {
    const url = '/admin/trade/inquiryorder/info';
    return this.get(url, data);
  }
  takeInquiryOrder(data: object): any {
    const url = '/admin/trade/inquiryorder/take';
    return this.post(url, data);
  }
  closeInquiryOrder(data: object): any {
    const url = '/admin/trade/inquiryorder/close';
    return this.post(url, data);
  }
  modifyInquiryOrderNotePrice(data: object): any {
    const url = '/admin/trade/inquiryorder/mod/noteprice';
    return this.post(url, data);
  }
  submitInquiryOrderQuoteUnable(data: object): any {
    const url = '/admin/trade/inquiryorder/quote/unable';
    return this.post(url, data);
  }
  submitInquiryOrderQuoteAble(data: object): any {
    const url = '/admin/trade/inquiryorder/quote/able';
    return this.post(url, data);
  }

  // orders - Trade Order
  getTradeOrderList(data: object): any {
    const url = '/admin/trade/tradeorder/list';
    return this.get(url, data);
  }
  getTradeOrderDetail(data: object): any {
    const url = '/admin/trade/tradeorder/info';
    return this.get(url, data);
  }
  takeTradeOrder(data: object): any {
    const url = '/admin/trade/tradeorder/take';
    return this.post(url, data);
  }
  closeTradeOrder(data: object): any {
    const url = '/admin/trade/tradeorder/close';
    return this.post(url, data);
  }
  submitTradeOrder(data: object): any {
    const url = '/admin/trade/tradeorder/submit';
    return this.post(url, data);
  }
  dealTradeOrder(data: object): any {
    const url = '/admin/trade/tradeorder/deal';
    return this.post(url, data);
  }
  notDealTradeOrder(data: object): any {
    const url = '/admin/trade/tradeorder/notdeal';
    return this.post(url, data);
  }
  modDealTradeOrder(data: object): any {
    const url = '/admin/trade/tradeorder/moddeal';
    return this.post(url, data);
  }
  scheduleTradeOrder(data: object): any {
    const url = '/admin/trade/tradeorder/schedule';
    return this.post(url, data);
  }
  getLifeCycleList(data: object): any {
    const url = '/admin/trade/tradeorder/lifecycle/list';
    return this.get(url, data);
  }

  // LCM
  getSimulateTradeOrderList(data: object): any {
    const url = '/admin/trade/simulatetradeorder/list';
    return this.get(url, data);
  }
  getSimulateTradeOrderDetail(data: object): any {
    const url = '/admin/trade/simulatetradeorder/info';
    return this.get(url, data);
  }

  // boss
  getBossUserList(data: object): any {
    const url = '/admin/boss/user/list';
    return this.get(url, data);
  }
  getBossUserInfo(data: object): any {
    const url = '/admin/boss/user/info';
    return this.post(url, data);
  }
  addBossUser(data: object): any {
    const url = '/admin/boss/manager/add';
    return this.post(url, data);
  }
  modifyBossUser(data: object): any {
    const url = '/admin/boss/user/modify';
    return this.post(url, data);
  }
  deleteBossUser(data: object): any {
    const url = '/admin/boss/user/delete';
    return this.post(url, data);
  }
  getBossRoles(data: object): any {
    const url = '/admin/boss/role/list';
    return this.get(url, data);
  }

  // oss  
  ossUploadUrl(data: object): any {
    const url = '/admin/system/oss/sign';
    return this.post(url, data);
  }
  getOssUploadUrl(data: object): any {
    const url = '/admin/system/oss/query';
    return this.post(url, data);
  }
}

export const userService = new UserService();
