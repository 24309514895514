<template>
  <div v-if="Component" class="svg-icon">
    <Component :fill="color" />
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineAsyncComponent } from 'vue';

// 动态导入 SVG 图标组件
const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
	color: {
    type: String,
    default: '#ADB1B6',
  },
});

const Component = defineAsyncComponent(() => import(`@/assets/icons/${props.icon}.svg`));
</script>

<style scoped>
.svg-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
}
</style>
